import { isDefined } from "@clipboard-health/util-ts";
import { IonButton } from "@ionic/react";
import { AppV2AccountRoutes } from "@src/app/routing/constant/appV2AccountRoutes";
import { useGetShiftFeedback } from "@src/appV2/ShiftFeedback/useGetShiftFeedback";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/appV2/Shifts/Shift/types";
import { useRevampWorkplaceSigningFlow } from "@src/appV2/Shifts/Shift/useRevampWorkplaceSigningFlow";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { Shift as DeprecatedShift } from "@src/lib/interface";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { AttendancePolicyCancelShiftButton } from "./attendancePolicyCancelShiftButton";

interface ShiftActionFooterProps {
  shift: DeprecatedShift;
  shiftStateData?: ShiftStateData;
  hasMissingDocs?: boolean;
  isConfirmAttendanceVisible?: boolean;
}

export const ShiftActionFooter = (props: ShiftActionFooterProps) => {
  const {
    shift,
    shiftStateData,
    hasMissingDocs = false,
    isConfirmAttendanceVisible = false,
  } = props;

  const { userId } = useDefinedWorker();
  const history = useHistory();
  const { qrCodeSignatureBeforeClockOutIsEnabled } = useRevampWorkplaceSigningFlow(
    shift.facilityId ?? ""
  );
  const { data: shiftFeedback } = useGetShiftFeedback(
    { workerId: userId, shiftId: shift._id ?? "" },
    {
      enabled: qrCodeSignatureBeforeClockOutIsEnabled && isDefined(shift._id) && isDefined(userId),
    }
  );
  const hasShiftFeedback = isDefined(shiftFeedback) && shiftFeedback.length > 0;

  const isInterested = shift.interested?.includes(userId) ?? false;
  const isAssigned = shift.agentId === userId;
  const isCancelled = !(isInterested || isAssigned);

  const isShiftOngoing = moment().isAfter(shift.start);
  const onlyShowCancelButton = isShiftOngoing && shift.isInstantPay && !shift.clockInOut;

  return !isCancelled || onlyShowCancelButton || hasMissingDocs ? (
    <>
      {!shiftStateData?.metadata.isShiftOver &&
        isDefined(shift.agent) &&
        !shift.verified &&
        !hasShiftFeedback && (
          // FIXME: Move this component to use Shift from appV2
          <AttendancePolicyCancelShiftButton
            shift={shift as unknown as Shift}
            fill={shift.clockInOut || isConfirmAttendanceVisible ? "clear" : "outline"}
          />
        )}

      {hasMissingDocs && !isDefined(shift.agent) && !isShiftOngoing && (
        <IonButton
          color="secondarybtn"
          shape="round"
          onClick={() => {
            history.push(AppV2AccountRoutes.DOCUMENTS);
          }}
          expand="block"
        >
          Upload Your Documents
        </IonButton>
      )}
    </>
  ) : null;
};
